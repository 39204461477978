export default {
  namespaced: true,
  state: {
    alertDeleteStatus: false
  },
  getters: {
    alertDeleteStatus: state => state.alertDeleteStatus
  },
  actions: {
    alertDeletePopup({ commit }, status) {
      commit('ALERT_DELETE_CLOSE', status)
    }
  },
  mutations: {
    ALERT_DELETE_CLOSE(state, status) {
      state.alertDeleteStatus = status
    }
  }
}
